import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import {
  DOWNPAYMENT_TYPE,
  DEFAULT_PAYOR_SCHEDULE_TYPE,
} from 'Data/payment-plan.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSubmit = useDataOnActionSubmit(props)
  let onActionReset = useDataOnActionReset(props)

  return async function onSubmit({ value, args, originalValue, change }) {
    switch (args?.type) {
      case 'submit': {
        return onActionSubmit({ value, args, originalValue, change })
      }
      case 'reset': {
        return onActionReset({ value, args, originalValue, change })
      }
      default: {
        throw new Error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

function useDataOnActionSubmit(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onAction({ value, args, originalValue, change }) {
    let mutationResponse = await executeMutation({
      data: {
        ...value.data,
        is_financials_managed_externally:
          value.data.is_financials_managed_externally,
        minimum_downpayment: toFixedOrPercentage(
          value.data.minimum_downpayment
        ),
        default_downpayment: toFixedOrPercentage(
          value.data.default_downpayment
        ),
        maximum_payment_plan_length: value.data.maximum_payment_plan_length,
        default_payor_schedule: {
          type: value.data.default_payor_schedule.type,
          installment_amount:
            value.data.default_payor_schedule.type ===
            DEFAULT_PAYOR_SCHEDULE_TYPE.INSTALLMENT_AMOUNT
              ? value.data.default_payor_schedule.installment_amount
              : undefined,
          payment_plan_length:
            value.data.default_payor_schedule.type ===
            DEFAULT_PAYOR_SCHEDULE_TYPE.PAYMENT_PLAN_LENGTH
              ? value.data.default_payor_schedule.payment_plan_length
              : undefined,
          allowed_dates: value.data.default_payor_schedule.allowed_dates,
        },
      },
      location_id: value.location_id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Treatment card settings updated!'))

    change(mutationResponse.data.insert_treatments_settings_one)
  }
}

function toFixedOrPercentage(value) {
  return {
    type: value.type,
    amount: value.type === DOWNPAYMENT_TYPE.FIXED ? value.amount : undefined,
    percentage:
      value.type === DOWNPAYMENT_TYPE.PERCENTAGE ? value.percentage : undefined,
  }
}

function useDataOnActionReset(props) {
  return async function onAction({ value, args, originalValue, change }) {
    change(originalValue)
  }
}
