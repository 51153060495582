import React, { useMemo } from 'react'
import View from './view.js'
import { useDataValue } from 'Simple/Data.js'
import { twoDigitsTime } from 'Data/format.js'

export default function Logic(props) {
  let slot_min_time = useDataValue({
    context: 'timegrid',
    path: 'slot_min_time',
    viewPath: props.viewPath,
  })

  let [start_hour, start_minutes] = slot_min_time
    .split(':')
    .map(_ => parseInt(_, 10))
  let [timePart, range] = props.time.split(/(am|pm)/).filter(Boolean)
  let [hours, minutes] = timePart.split(':').map(_ => parseInt(_, 10))
  if (!minutes) minutes = 0

  let showHourLabel = useMemo(() => {
    return (
      // This ensures we show the hour at the starting minute
      (start_hour === hours && start_minutes === minutes) ||
      /**
       * Since `slotLabelInterval` is 10 by default, we check if the `start_minutes` are divisible by 10
       * If so, then we display the hour at 00 minutes
       * If not, we display the hours whenever the `minutes` match with the `start_minutes`
       */
      (start_minutes % 10 === 0 ? minutes === 0 : start_minutes === minutes)
    )
  }, [start_hour, hours, start_minutes, minutes])

  return (
    <View
      {...props}
      hours={showHourLabel && hours}
      range={showHourLabel && range}
      minutes={twoDigitsTime(minutes)}
    />
  )
}
