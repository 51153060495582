import { useDataChange, useDataValue } from 'Simple/Data.js'
import { getDefaultSecondaryPayor } from 'Data/payment-plan.js'

export function useOnClick(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let settings = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      // there should always be at least one payor and it is primary
      // so only a secondary payor could be added
      next.payors.push(getDefaultSecondaryPayor(next, treatment_plan, settings))
    })
  }
}
