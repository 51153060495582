import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getNumberOfInstallmentsPerMonth,
  getPayorShareAfterDiscounts,
  getFirstDueDate,
  isWeekly,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let settings = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      let previous_installment_interval = payor.installment_interval
      payor.installment_interval = value
      // reset these values when interval changed because
      //  it is used for both storing the day of the week and day of the month
      payor.first_installment_date = isWeekly(value)
        ? 1
        : settings.default_payor_schedule.allowed_dates?.[0] ?? 1
      payor.second_installment_date = 1
      payor.first_due_date = getFirstDueDate(payor.first_installment_date)

      let base = getPayorShareAfterDiscounts(payor, next)
      let total_installments_amount = base - payor.downpayment_amount

      let from_frequency = getNumberOfInstallmentsPerMonth(
        previous_installment_interval
      )
      let to_frequency = getNumberOfInstallmentsPerMonth(value)

      let installment_amount = Math.round(
        payor.installment_amount * (from_frequency / to_frequency)
      )
      // reset the installment amount to reflect the new interval
      payor.installment_amount = parseFloat(installment_amount.toFixed(2))
      // recalculate payment plan length if problems with rounding
      payor.payment_plan_length =
        payor.installment_amount > 0
          ? Math.ceil(
              total_installments_amount /
                (payor.installment_amount * to_frequency)
            )
          : 0
    })
  }
}
