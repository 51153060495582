import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

let DEFAULT_SETTINGS = {
  default_view: 'compact',
  compact_view_notes: 'compact',
  is_financials_managed_externally: false,
  minimum_downpayment: {
    type: 'FIXED',
    amount: 0,
  },
  default_downpayment: {
    type: 'FIXED',
    amount: 0,
  },
  maximum_payment_plan_length: {
    value: 60,
    type: 'TOTAL',
  },
  default_payor_schedule: {
    type: 'PAYMENT_PLAN_LENGTH',
    payment_plan_length: {
      value: 1,
      type: 'TOTAL',
    },
    allowed_dates: Array(31)
      .fill()
      .map((_, index) => index + 1),
  },
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return useMemo(() => {
    if (!data?.[0]) {
      return { id: location_id, ...DEFAULT_SETTINGS }
    }

    return {
      id: location_id,
      ...DEFAULT_SETTINGS,
      ...data[0].data,
    }
  }, [data, location_id])
}
