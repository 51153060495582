import { useDataChange } from 'Simple/Data.js'

export function useOnClickFixed(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'settings',
    path: 'data.default_downpayment.type',
  })

  return function onClick() {
    change('FIXED')
  }
}

export function useOnClickPercentage(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'settings',
    path: 'data.default_downpayment.type',
  })

  return function onClick() {
    change('PERCENTAGE')
  }
}
