import { useMemo } from 'react'
import { useDataFormat, useDataValue } from 'Simple/Data.js'
import { isWeekly } from 'Data/payment-plan.js'

let WEEK_DAYS = [
  {
    id: 1,
    text: 'Monday',
  },
  {
    id: 2,
    text: 'Tuesday',
  },
  {
    id: 3,
    text: 'Wednesday',
  },
  {
    id: 4,
    text: 'Thursday',
  },
  {
    id: 5,
    text: 'Friday',
  },
  {
    id: 6,
    text: 'Saturday',
  },
  {
    id: 7,
    text: 'Sunday',
  },
]

export default function useDataTransform(props, data) {
  let is_weekly_interval = useDataFormat({
    context: 'payor',
    path: 'installment_interval',
    format: isWeekly,
    viewPath: props.viewPath,
  })
  let allowed_dates = useDataFormat({
    context: 'settings',
    path: 'default_payor_schedule.allowed_dates',
    format: getDaysOfMonth,
    viewPath: props.viewPath,
  })

  return useMemo(
    () => (is_weekly_interval ? WEEK_DAYS : allowed_dates),
    [is_weekly_interval, allowed_dates]
  )
}

function getDaysOfMonth(allowed_dates) {
  let result = []
  for (let i of allowed_dates) {
    result.push({ id: i, text: `${i}${getSuffix(i)} of every month` })
  }
  return result
}

function getSuffix(n) {
  let s = ['th', 'st', 'nd', 'rd']
  let v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}
