import { useDataChange } from 'Simple/Data.js'

export function useOnClickTotal(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'settings',
    path: 'data.default_payor_schedule.payment_plan_length.type',
  })

  return function onClick() {
    change('TOTAL')
  }
}

export function useOnClickRelative(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'settings',
    path: 'data.default_payor_schedule.payment_plan_length.type',
  })

  return function onClick() {
    change('RELATIVE')
  }
}
