import { formatISO, isBefore, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export function shouldShow({ state, start, location: { time_zone_id } }) {
  // Show No Show only for today's appointment and whose start time is in the past
  let isToday = isBefore(
    startOfDay(utcToZonedTime(new Date(), time_zone_id)),
    new Date(start)
  )

  let isBeforeNow = isBefore(
    new Date(start),
    utcToZonedTime(new Date(), time_zone_id)
  )

  return state === 'SCHEDULED' && isToday && isBeforeNow
}
