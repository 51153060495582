import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'settings',
    path: 'data.default_payor_schedule.allowed_dates',
    viewPath: props.viewPath,
  })
  let allowedDates = useDataValue({
    context: 'settings',
    path: 'data.default_payor_schedule.allowed_dates',
    viewPath: props.viewPath,
  })
  let dayId = useDataValue({
    context: 'day',
    path: 'id',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(
      value
        ? [...allowedDates, dayId]
        : allowedDates.filter(day => day !== dayId)
    )
  }
}
