import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'location_id',
  })

  return useMemo(() => {
    if (!data) return data

    let settings = data?.[0]?.data ?? {}

    return {
      id: data[0]?.id,
      location_id,
      data: {
        ...settings,
        default_view: settings.default_view ?? 'timeline',
        compact_view_notes: settings.compact_view_notes ?? 'compact',
      },
    }
  }, [data, location_id])
}
