import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import {
  DEFAULT_PAYOR_SCHEDULE_TYPE,
  DOWNPAYMENT_TYPE,
  PAYMENT_PLAN_LENGTH_TYPE,
} from 'Data/payment-plan.js'

// These defaults serve 2 purposes:
// - when no settings configured before, the defaults will be added
// - when reading existing settings, it adds some sensible defaults for the alternative options (e.g. have percentage set to 0 when switching from FIXED to PERCENTAGE, instead of just showing a blank input)
let DEFAULT_SETTINGS = {
  default_view: 'compact',
  compact_view_notes: 'compact',
  is_financials_managed_externally: false,
  minimum_downpayment: {
    type: DOWNPAYMENT_TYPE.FIXED,
    amount: 0,
    percentage: 0,
  },
  default_downpayment: {
    type: DOWNPAYMENT_TYPE.FIXED,
    amount: 0,
    percentage: 0,
  },
  maximum_payment_plan_length: {
    value: 60,
    type: PAYMENT_PLAN_LENGTH_TYPE.TOTAL,
  },
  default_payor_schedule: {
    type: DEFAULT_PAYOR_SCHEDULE_TYPE.PAYMENT_PLAN_LENGTH,
    installment_amount: 0,
    payment_plan_length: {
      value: 1,
      type: PAYMENT_PLAN_LENGTH_TYPE.TOTAL,
    },
    allowed_dates: Array(31)
      .fill(0)
      .map((_, index) => index + 1),
  },
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'location_id',
  })

  return useMemo(() => {
    if (!data?.[0]) {
      return {
        id: null,
        location_id,
        data: DEFAULT_SETTINGS,
      }
    }

    let settings = data[0].data

    return {
      id: data[0].id,
      location_id,
      data: {
        ...settings,
        is_financials_managed_externally:
          settings.is_financials_managed_externally ?? false,
        minimum_downpayment: {
          ...DEFAULT_SETTINGS.minimum_downpayment,
          ...(settings.minimum_downpayment || {}),
        },
        default_downpayment: {
          ...DEFAULT_SETTINGS.default_downpayment,
          ...(settings.default_downpayment || {}),
        },
        maximum_payment_plan_length: {
          ...DEFAULT_SETTINGS.maximum_payment_plan_length,
          ...(settings.maximum_payment_plan_length || {}),
        },
        default_payor_schedule: {
          ...DEFAULT_SETTINGS.default_payor_schedule,
          ...(settings.default_payor_schedule || {}),
        },
      },
    }
  }, [data, location_id])
}
