// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPayments/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPayments_Content($location_id: uuid!, $data: jsonb) {
  insert_treatments_settings_one(
    object: { location_id: $location_id, data: $data }
    on_conflict: { constraint: settings_location_id_key, update_columns: data }
  ) {
    id
    location_id
    data
  }
}

`