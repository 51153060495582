/**
 * This file includes helper functions used inside the payment plan configuration under apps/admin/src/App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlan.
 */

import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  compareAsc,
  differenceInCalendarMonths,
  endOfMonth,
  endOfYear,
  formatISO,
  getYear,
  isAfter,
  isEqual,
  max,
  min,
  parseISO,
  setDate,
  setYear,
  startOfMonth,
} from 'date-fns'

export let INCREMENT_STEP = 5

export let LOCK = /** @type {const} */ ({
  INSTALLMENT_AMOUNT: 'INSTALLMENT_AMOUNT',
  DOWNPAYMENT_AMOUNT: 'DOWNPAYMENT_AMOUNT',
  PAYMENT_PLAN_LENGTH: 'PAYMENT_PLAN_LENGTH',
})

export let INTERVAL = /** @type {const} */ ({
  WEEKLY: 'Weekly',
  EVERY_SECOND_WEEK: 'EverySecondWeek',
  TWICE_PER_MONTH: 'TwicePerMonth',
  MONTHLY: 'Monthly',
})

export let INSURANCE_INSTALLMENT_INTERVAL = /** @type {const} */ ({
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
})

export let COORDINATION_OF_BENEFITS_TYPE = /** @type {const} */ ({
  STANDARD: 'Standard',
  NON_DUPLICATING: 'NonDuplicating',
})

export let DOWNPAYMENT_TYPE = /** @type {const} */ ({
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
})

export let DOWNPAYMENT_PERCENTAGE_BASE_TYPE = /** @type {const} */ ({
  TX_FEE: 'TX_FEE',
  LIFETIME_COVERAGE: 'LIFETIME_COVERAGE',
})

export let AGE_LIMIT_CUTOFF = /** @type {const} */ ({
  IMMEDIATE: 'Immediate',
  END_OF_MONTH: 'EndOfMonth',
  END_OF_YEAR: 'EndOfYear',
})

export let DEFAULT_PAYOR_SCHEDULE_TYPE = /** @type {const} */ ({
  INSTALLMENT_AMOUNT: 'INSTALLMENT_AMOUNT',
  PAYMENT_PLAN_LENGTH: 'PAYMENT_PLAN_LENGTH',
})

export let PAYMENT_PLAN_LENGTH_TYPE = /** @type {const} */ ({
  /**
   * Fixed payment plan length
   */
  TOTAL: 'TOTAL',
  /**
   * Payment plan length calculated relative to treatment plan length (value could be negative)
   */
  RELATIVE: 'RELATIVE',
})

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export function isWeekly(value) {
  return [INTERVAL.WEEKLY, INTERVAL.EVERY_SECOND_WEEK].includes(value)
}

/**
 * Returns the number of installments in a month depending on the chosen interval
 * @param {*} installment_interval
 * @returns {number}
 */
export function getNumberOfInstallmentsPerMonth(installment_interval) {
  switch (installment_interval) {
    case INTERVAL.WEEKLY:
      return 4
    case INTERVAL.EVERY_SECOND_WEEK:
    case INTERVAL.TWICE_PER_MONTH:
      return 2
    case INTERVAL.MONTHLY:
    default:
      return 1
  }
}

/**
 * Returns the total treatment fee after applying the discounts and charges.
 *
 * Note: only the discounts applied on treatment fee are taken into account at this stage.
 *
 * @param {*} payment_plan
 * @returns {number}
 */
export function getAdjustedTreatmentFeeBeforeInsurances(payment_plan) {
  // discounts could be either 'fixed amount' or 'percentage', but the values should always be in sync
  let discounts = payment_plan.discounts
    .filter(discount => !discount.is_applied_to_payor)
    .reduce(
      (acc, discount) => acc - discount.amount,
      payment_plan.treatment_fee
    )
  let result = payment_plan.charges.reduce(
    (acc, charge) => acc + charge.amount,
    discounts
  )

  return parseFloat(Math.max(result, 0).toFixed(2))
}

/**
 * Returns the initial configuration for the payor (i.e. pay in full 100% of the remaining fee)
 *
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
export function getDefaultPrimaryPayor(payment_plan, treatment_plan, settings) {
  let treatment_fee = getAdjustedTreatmentFeeAfterInsurances(payment_plan)
  let downpayment_amount = getDefaultPayorDownpayment(treatment_fee, settings)
  let total_installments_amount = parseFloat(
    (treatment_fee - downpayment_amount).toFixed(2)
  )
  let { installment_amount, payment_plan_length } =
    getPayorDefaultInstallmentAmountAndPaymentPlanLength(
      total_installments_amount,
      treatment_plan,
      settings
    )
  let first_installment_date =
    settings.default_payor_schedule.allowed_dates?.[0] ?? 1
  return {
    id: crypto.randomUUID(),
    person_id: null,
    is_primary: true,
    share: {
      type: 'percentage',
      amount: treatment_fee,
      percentage: 100,
    },
    downpayment_amount,
    installment_amount,
    payment_plan_length,
    installment_interval: INTERVAL.MONTHLY,
    first_due_date: getFirstDueDate(first_installment_date),
    first_installment_date,
    second_installment_date: 1,
    lock: null,
  }
}

/**
 *
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {import('./payment-plan.types.ts').Settings} settings
 * @returns
 */
export function getDefaultSecondaryPayor(
  payment_plan,
  treatment_plan,
  settings
) {
  let remaining_fee = getAdjustedTreatmentFeeAfterInsurances(payment_plan)
  let primary_payor = payment_plan.payors.find(payor => payor.is_primary)
  let share_amount = remaining_fee - primary_payor.share.amount

  let downpayment_amount = getDefaultPayorDownpayment(share_amount, settings)
  let { installment_amount, payment_plan_length } =
    getPayorDefaultInstallmentAmountAndPaymentPlanLength(
      share_amount - downpayment_amount,
      treatment_plan,
      settings
    )

  let first_installment_date =
    settings.default_payor_schedule.allowed_dates?.[0] ?? 1
  return {
    id: crypto.randomUUID(),
    person_id: null,
    is_primary: false,
    share: {
      type: 'percentage',
      amount: share_amount,
      percentage: 100 - primary_payor.share.percentage,
    },
    downpayment_amount,
    installment_amount,
    payment_plan_length,
    installment_interval: INTERVAL.MONTHLY,
    first_due_date: getFirstDueDate(first_installment_date),
    first_installment_date,
    second_installment_date: 1,
    lock: null,
  }
}

/**
 *
 * @param {number} installment_date
 */
export function getFirstDueDate(installment_date) {
  let value = setDate(addMonths(startOfMonth(new Date()), 1), installment_date)
  return formatISO(value, {
    representation: 'date',
  })
}

/**
 * Calculates the initial values of the installment amount and payment plan length of a newly added payor according to the settings.
 *
 * @param {number} total_installments_amount
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
function getPayorDefaultInstallmentAmountAndPaymentPlanLength(
  total_installments_amount,
  treatment_plan,
  settings
) {
  let installment_amount = 0
  let payment_plan_length = 0
  switch (settings.default_payor_schedule.type) {
    case DEFAULT_PAYOR_SCHEDULE_TYPE.PAYMENT_PLAN_LENGTH: {
      payment_plan_length = getPayorDefaultPaymentPlanLength(
        treatment_plan,
        settings
      )
      installment_amount =
        payment_plan_length > 0
          ? parseFloat(
              (total_installments_amount / payment_plan_length).toFixed(2)
            )
          : total_installments_amount

      break
    }

    case DEFAULT_PAYOR_SCHEDULE_TYPE.INSTALLMENT_AMOUNT: {
      installment_amount = Math.min(
        settings.default_payor_schedule.installment_amount,
        total_installments_amount
      )
      payment_plan_length =
        installment_amount > 0
          ? Math.ceil(total_installments_amount / installment_amount)
          : 0

      break
    }

    default: {
      installment_amount = 0
      payment_plan_length = 0

      break
    }
  }

  return { installment_amount, payment_plan_length }
}

/**
 * Returns the default downpayment amount for given payor. Takes into account the settings.
 *
 * @param {number} payor_share
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
function getDefaultPayorDownpayment(payor_share, settings) {
  let default_downpayment_amount = 0

  switch (settings.default_downpayment.type) {
    case DOWNPAYMENT_TYPE.FIXED: {
      default_downpayment_amount = settings.default_downpayment.amount
      break
    }

    case DOWNPAYMENT_TYPE.PERCENTAGE: {
      default_downpayment_amount = parseFloat(
        (payor_share * settings.default_downpayment.percentage).toFixed(2)
      )
      break
    }

    default: {
      default_downpayment_amount = 0
      break
    }
  }

  if (
    default_downpayment_amount > 0 &&
    payor_share > default_downpayment_amount
  ) {
    return default_downpayment_amount
  } else {
    return payor_share
  }
}

/**
 * Returns the minimum downpayment amount the given payor is allowed to pay according to the settings.
 *
 * @param {number} payor_share
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
export function getPayorMinimumDownpaymentAmount(payor_share, settings) {
  let minimum_downpayment_amount = 0
  switch (settings.minimum_downpayment.type) {
    case DOWNPAYMENT_TYPE.FIXED: {
      minimum_downpayment_amount = settings.minimum_downpayment.amount
      break
    }

    case DOWNPAYMENT_TYPE.PERCENTAGE: {
      minimum_downpayment_amount = parseFloat(
        (payor_share * settings.minimum_downpayment.percentage).toFixed(2)
      )
      break
    }

    default: {
      minimum_downpayment_amount = 0
      break
    }
  }

  // prevent it from going over payor's total share
  return Math.min(minimum_downpayment_amount, payor_share)
}

/**
 *
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
export function getMaximumPaymentPlanLength(treatment_plan, settings) {
  switch (settings.maximum_payment_plan_length.type) {
    case 'TOTAL':
      return settings.maximum_payment_plan_length.value
    case 'RELATIVE':
      return (
        weeksToMonths(treatment_plan.length_in_weeks) +
        settings.maximum_payment_plan_length.value
      )
    default:
      return 60
  }
}

/**
 *
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {import('./payment-plan.types.ts').Settings} settings
 */
export function getPayorDefaultPaymentPlanLength(treatment_plan, settings) {
  if (settings.default_payor_schedule.type !== 'PAYMENT_PLAN_LENGTH') {
    return 1
  }

  let result = 1
  switch (settings.default_payor_schedule.payment_plan_length.type) {
    case 'TOTAL': {
      result = settings.default_payor_schedule.payment_plan_length.value
      break
    }
    case 'RELATIVE': {
      result =
        weeksToMonths(treatment_plan.length_in_weeks) +
        settings.default_payor_schedule.payment_plan_length.value
      break
    }
    default: {
      result = 1
      break
    }
  }

  // ensure it won't go over the max payment plan length configured
  return Math.min(result, getMaximumPaymentPlanLength(treatment_plan, settings))
}

/**
 *
 * @param {number} value
 * @returns
 */
export function weeksToMonths(value) {
  // To convert weeks to months we divide by 4.34524, that is how many weeks are in a month
  // 365 (days in a year) / 7 (days in a week) / 12 (months in a year) = 4.34524
  // Core performs this calculation
  return Math.round(value / 4.34524)
}

/**
 * Get payor's remaining amount to pay after applying their discounts.
 *
 * @param {*} payor
 * @param {*} payment_plan
 * @returns
 */
export function getPayorShareAfterDiscounts(payor, payment_plan) {
  return parseFloat(
    Math.max(
      payor.share.amount - getPayorDiscountsAmount(payor, payment_plan),
      0
    ).toFixed(2)
  )
}

export function getPayorDiscountsAmount(payor, payment_plan) {
  return payment_plan.discounts
    .filter(discount => isDiscountAppliedToPayor(payor, discount))
    .reduce((acc, discount) => acc + discount.amount, 0)
}

/**
 * Finds the payor that the discount is applied to, if the discount is applied to a payor's share.
 *
 * @param {*} discount
 * @param {*} payment_plan
 * @returns
 */
export function getPayorForDiscount(discount, payment_plan) {
  return payment_plan.payors.find(payor =>
    isDiscountAppliedToPayor(payor, discount)
  )
}

/**
 * Returns true if the given discount is applied on payor's share
 *
 * @param {*} payor
 * @param {*} discount
 * @returns
 */
export function isDiscountAppliedToPayor(payor, discount) {
  return (
    discount.is_applied_to_payor &&
    ((discount.payor_person_id &&
      discount.payor_person_id === payor.person_id) ||
      (!discount.payor_person_id && payor.is_primary))
  )
}

/**
 *
 * @param {*} payment_plan
 * @returns
 */
export function getRemainingAmount(payment_plan) {
  let payors_share_amount = payment_plan.payors.reduce(
    (acc, payor) => acc + payor.share.amount,
    0
  )

  return parseFloat(
    (
      getAdjustedTreatmentFeeAfterInsurances(payment_plan) - payors_share_amount
    ).toFixed(2)
  )
}

/**
 * Recalculates payor's downpayment, installment amount and payment plan length when the payor's share has changed.
 *
 * @param {*} payor
 * @param {*} payment_plan
 */
export function getUpdatedPayorConfiguration(payor, payment_plan) {
  let discounts_amount = getPayorDiscountsAmount(payor, payment_plan)
  let remaining_amount = parseFloat(
    Math.max(payor.share.amount - discounts_amount, 0).toFixed(2)
  )
  let downpayment_amount
  let installment_amount
  let payment_plan_length
  if (payor.installment_amount === 0 || payor.payment_plan_length === 0) {
    downpayment_amount = remaining_amount
    installment_amount = 0
    payment_plan_length = 0
  } else {
    // keep the same downpayment
    downpayment_amount = Math.min(payor.downpayment_amount, remaining_amount)
    let total_installments_amount = remaining_amount - payor.downpayment_amount
    let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
      payor.installment_interval
    )
    let current_monthly_installment_amount =
      payor.installment_amount * number_of_installments_per_month
    let lower_threshold =
      current_monthly_installment_amount * (payor.payment_plan_length - 1)
    let upper_threshold =
      current_monthly_installment_amount * payor.payment_plan_length

    if (
      total_installments_amount > lower_threshold &&
      total_installments_amount < upper_threshold &&
      payor.payment_plan_length > 1
    ) {
      // the updated amount is within bounds (no changes needed, the last installment amount will reflect the change)
      installment_amount = payor.installment_amount
      payment_plan_length = payor.payment_plan_length
    } else if (total_installments_amount === 0) {
      // less than the previous downpayment, set to pay in full
      installment_amount = 0
      payment_plan_length = 0
    } else if (payor.lock === LOCK.INSTALLMENT_AMOUNT) {
      // keep the same installment amount and only adjust the payment plan length
      installment_amount = payor.installment_amount
      payment_plan_length =
        installment_amount > 0
          ? Math.ceil(
              total_installments_amount /
                (payor.installment_amount * number_of_installments_per_month)
            )
          : 0
    } else if (payor.payment_plan_length === 1) {
      installment_amount = total_installments_amount
      payment_plan_length = 1
    } else {
      let total_number_of_installments =
        payor.payment_plan_length * number_of_installments_per_month
      // rounding as ceil to try and keep the same length
      installment_amount = parseFloat(
        Math.ceil(
          total_installments_amount / total_number_of_installments
        ).toFixed(2)
      )
      // ensure rounding is not affecting the number of instalments
      payment_plan_length =
        installment_amount > 0
          ? Math.ceil(
              total_installments_amount /
                (installment_amount * number_of_installments_per_month)
            )
          : 0
    }
  }
  return { downpayment_amount, installment_amount, payment_plan_length }
}

/**
 * Calculate the last installment amount
 *
 * @param {*} payor
 * @param {*} payment_plan
 */
export function getLastInstallmentAmount(payor, payment_plan) {
  if (payor.payment_plan_length === 0 || payor.installment_amount === 0) {
    return 0
  }

  let discounts_amount = getPayorDiscountsAmount(payor, payment_plan)
  let total_installments_amount = Math.max(
    payor.share.amount - discounts_amount - payor.downpayment_amount,
    0
  )
  if (total_installments_amount === 0) return 0

  let number_of_installments = Math.ceil(
    total_installments_amount / payor.installment_amount
  )
  return parseFloat(
    (
      total_installments_amount -
      payor.installment_amount * (number_of_installments - 1)
    ).toFixed(2)
  )
}

/**
 * Get the treatment fee used as the base for the calculation of the insurance coverage.
 *
 * @param {*} payment_plan
 * @returns
 */
export function getInsuranceTreatmentFee(payment_plan) {
  let charges = payment_plan.charges
    .filter(charge => charge.is_included_in_insurance_claim)
    .reduce((acc, charge) => acc + charge.amount, 0)

  // discounts could be either 'fixed amount' or 'percentage', but the values should always be in sync
  let discounts = payment_plan.discounts
    // adding this filter for completeness,
    // discounts applied to payor's share should not be included in insurance claim (only treatment fee are relevant here)
    .filter(discount => !discount.is_applied_to_payor)
    .filter(discount => discount.is_included_in_insurance_claim)
    .reduce((acc, discount) => acc + discount.amount, 0)

  return parseFloat(
    (payment_plan.treatment_fee + charges - discounts).toFixed(2)
  )
}

/**
 *
 * @param {*} payment_plan
 * @returns
 */
export function getAdjustedTreatmentFeeAfterInsurances(payment_plan) {
  let treatment_fee = getAdjustedTreatmentFeeBeforeInsurances(payment_plan)
  if (!payment_plan.insurances.length) {
    return treatment_fee
  }
  let estimated_reimbursement_amount = payment_plan.insurances.reduce(
    (acc, insurance) => acc + insurance.estimated_reimbursement_amount,
    0
  )
  return treatment_fee - estimated_reimbursement_amount
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function getTotalDeductibleAmount(insurance, treatment_plan) {
  let start_date = new Date()
  let end_date = addWeeks(start_date, treatment_plan.length_in_weeks ?? 0)
  let ortho_insured = insurance.insured.tx_category_insured.ortho_insured
  let start_year = ortho_insured.year_deductible_paid_last
    ? Math.max(getYear(start_date), ortho_insured.year_deductible_paid_last)
    : getYear(start_date)
  let end_year = getYear(end_date)
  let calendar_years_spanned = end_year - start_year + 1

  return (
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages.deductible_amount *
    calendar_years_spanned
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} primary_insurance
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function getPrimaryInsuranceMaximumReimbursementAmount(
  primary_insurance,
  payment_plan,
  treatment_plan
) {
  let treatment_fee = getInsuranceTreatmentFee(payment_plan)
  let total_deductible = getTotalDeductibleAmount(
    primary_insurance,
    treatment_plan
  )
  return getMaximumReimbursementAmount(
    primary_insurance,
    treatment_fee,
    total_deductible
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} primary_insurance
 * @param {import('./payment-plan.types.ts').Insurance} secondary_insurance
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function getSecondaryInsuranceMaximumReimbursementAmount(
  primary_insurance,
  secondary_insurance,
  payment_plan,
  treatment_plan
) {
  let primary_insurance_reimbursement =
    primary_insurance && primary_insurance.insured_id
      ? getPrimaryInsuranceMaximumReimbursementAmount(
          primary_insurance,
          payment_plan,
          treatment_plan
        )
      : 0
  let total_deductible = getTotalDeductibleAmount(
    secondary_insurance,
    treatment_plan
  )
  let treatment_fee = getInsuranceTreatmentFee(payment_plan)
  if (
    secondary_insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages.cob_type ===
    COORDINATION_OF_BENEFITS_TYPE.NON_DUPLICATING
  ) {
    // calculate the reimbursement amount as if it was the primary insurance
    // and then it will only cover the difference, if it was to cover more than the primary insurance covers
    let maximum_reimbursement = getMaximumReimbursementAmount(
      secondary_insurance,
      treatment_fee,
      total_deductible
    )
    return Math.max(maximum_reimbursement - primary_insurance_reimbursement, 0)
  } else {
    return Math.min(
      getMaximumReimbursementAmount(
        secondary_insurance,
        treatment_fee,
        total_deductible
      ),
      treatment_fee - primary_insurance_reimbursement
    )
  }
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 * @param {number} treatment_fee
 * @param {number} total_deductible
 * @returns
 */
function getMaximumReimbursementAmount(
  insurance,
  treatment_fee,
  total_deductible
) {
  let maximum_reimbursement =
    parseFloat(
      (
        treatment_fee *
        insurance.insured.insurance_subscription.insurance_plan
          .tx_category_coverages.ortho_coverages.reimbursement_percentage
      ).toFixed(2)
    ) - total_deductible
  let remaining_coverage =
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages.max_lifetime_coverage -
    insurance.insured.tx_category_insured.ortho_insured.used_lifetime_coverage

  // making sure it won't end up with a negative value
  // and that it doesn't go over the total treatment fee
  return Math.min(
    Math.max(Math.min(remaining_coverage, maximum_reimbursement), 0),
    treatment_fee
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 * @param {number} treatment_fee
 */
export function getInsuranceDownpayment(
  insurance,
  treatment_fee,
  maximum_reimbursement_amount
) {
  let ortho_coverage =
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages
  let result = 0
  if (ortho_coverage.downpayment_type === DOWNPAYMENT_TYPE.FIXED) {
    result = ortho_coverage.downpayment_amount
  } else {
    switch (ortho_coverage.downpayment_percentage_base) {
      case DOWNPAYMENT_PERCENTAGE_BASE_TYPE.LIFETIME_COVERAGE:
        result = parseFloat(
          (
            ortho_coverage.max_lifetime_coverage *
            ortho_coverage.downpayment_percentage
          ).toFixed(2)
        )
        break
      case DOWNPAYMENT_PERCENTAGE_BASE_TYPE.TX_FEE:
        result = parseFloat(
          (
            treatment_fee *
            ortho_coverage.reimbursement_percentage *
            ortho_coverage.downpayment_percentage
          ).toFixed(2)
        )
        break
      default:
        result = 0
        break
    }
  }

  return Math.min(result, maximum_reimbursement_amount)
}

/**
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {number} maximum_reimbursement_amount
 * @param {number} downpayment_amount
 */
export function getInsuranceInstallmentAmount(
  insurance,
  treatment_plan,
  maximum_reimbursement_amount,
  downpayment_amount
) {
  let start_date = new Date()
  let end_date = addWeeks(start_date, treatment_plan.length_in_weeks ?? 0)
  let length_in_months = differenceInCalendarMonths(end_date, start_date)
  if (length_in_months === 0) return 0

  let monthly_installment_amount = parseFloat(
    (
      (maximum_reimbursement_amount - downpayment_amount) /
      length_in_months
    ).toFixed(2)
  )

  return parseFloat(
    (
      monthly_installment_amount *
      getNumberOfMonthsInInterval(
        insurance.insured.insurance_subscription.insurance_plan
          .insurance_company.installment_interval
      )
    ).toFixed(2)
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} primary_insurance
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function getPrimaryInsuranceEstimatedReimbursement(
  primary_insurance,
  payment_plan,
  treatment_plan
) {
  let treatment_fee = getInsuranceTreatmentFee(payment_plan)
  let maximum_reimbursement_amount =
    getPrimaryInsuranceMaximumReimbursementAmount(
      primary_insurance,
      payment_plan,
      treatment_plan
    )

  return getEstimatedReimbursementAmount(
    primary_insurance,
    treatment_plan,
    maximum_reimbursement_amount,
    treatment_fee
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} primary_insurance
 * @param {import('./payment-plan.types.ts').Insurance} secondary_insurance
 * @param {*} payment_plan
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function getSecondaryInsuranceEstimatedReimbursement(
  primary_insurance,
  secondary_insurance,
  payment_plan,
  treatment_plan
) {
  // allow selecting a secondary insurance while the primary insurance is a placeholder
  let primary_insurance_estimated_reimbursement_amount =
    primary_insurance?.estimated_reimbursement_amount ?? 0

  let maximum_reimbursement_amount =
    getSecondaryInsuranceMaximumReimbursementAmount(
      primary_insurance,
      secondary_insurance,
      payment_plan,
      treatment_plan
    )

  let treatment_fee = getInsuranceTreatmentFee(payment_plan)
  let result = getEstimatedReimbursementAmount(
    secondary_insurance,
    treatment_plan,
    maximum_reimbursement_amount,
    treatment_fee
  )

  return {
    estimated_reimbursement_amount: Math.min(
      result.estimated_reimbursement_amount,
      treatment_fee - primary_insurance_estimated_reimbursement_amount
    ),
    downpayment_amount: result.downpayment_amount,
    installment_amount: result.installment_amount,
    loss_due_to_effective_date: result.loss_due_to_effective_date,
    loss_due_to_age_limit: result.loss_due_to_age_limit,
  }
}

/**
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @param {number} maximum_reimbursement_amount
 * @param {number} treatment_fee
 */
function getEstimatedReimbursementAmount(
  insurance,
  treatment_plan,
  maximum_reimbursement_amount,
  treatment_fee
) {
  let downpayment_amount = getInsuranceDownpayment(
    insurance,
    treatment_fee,
    maximum_reimbursement_amount
  )
  let installment_amount = getInsuranceInstallmentAmount(
    insurance,
    treatment_plan,
    maximum_reimbursement_amount,
    downpayment_amount
  )

  let start_date = new Date()
  let end_date = addWeeks(start_date, treatment_plan.length_in_weeks ?? 0)
  let date = max([getEffectiveStartDate(insurance) ?? start_date, start_date])

  let remaining = maximum_reimbursement_amount
  let loss_due_to_age_limit = 0
  let last_round = false
  while (!last_round) {
    last_round = isEqual(date, end_date) || isAfter(date, end_date)

    let payment_amount = isEqual(date, start_date)
      ? Math.min(downpayment_amount, remaining)
      : Math.min(installment_amount, remaining)
    let coverage_end_date = getCoverageEndDate(insurance)
    if (coverage_end_date && isAfter(date, coverage_end_date)) {
      loss_due_to_age_limit = parseFloat(
        (loss_due_to_age_limit + payment_amount).toFixed(2)
      )
      payment_amount = 0
    }

    remaining = parseFloat((remaining - payment_amount).toFixed(2))
    date = min([
      addMonths(
        date,
        getNumberOfMonthsInInterval(
          insurance.insured.insurance_subscription.insurance_plan
            .insurance_company.installment_interval
        )
      ),
      end_date,
    ])
  }

  let loss_due_to_effective_date = parseFloat(
    (remaining - loss_due_to_age_limit).toFixed(2)
  )
  let estimated_reimbursement_amount = insurance.is_overridden
    ? insurance.estimated_reimbursement_amount
    : parseFloat(
        Math.max(maximum_reimbursement_amount - remaining, 0).toFixed(2)
      )

  if (downpayment_amount > estimated_reimbursement_amount) {
    downpayment_amount = estimated_reimbursement_amount
    installment_amount = 0
  }

  return {
    estimated_reimbursement_amount,
    downpayment_amount,
    installment_amount,
    loss_due_to_effective_date,
    loss_due_to_age_limit,
  }
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 */
export function getEffectiveStartDate(insurance) {
  let ortho_coverage =
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages
  if (!insurance.insured.insurance_subscription.enrollment_date) {
    return null
  }

  return addDays(
    new Date(insurance.insured.insurance_subscription.enrollment_date),
    ortho_coverage.waiting_period ?? 0
  )
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance} insurance
 */
function getCoverageEndDate(insurance) {
  let ortho_coverage =
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages
  if (
    insurance.insured.insurance_subscription.person.id ===
      insurance.insured.patient.person.id ||
    !insurance.insured.patient.person.birth_date ||
    !ortho_coverage.dependent_age_limit ||
    !ortho_coverage.age_limit_cutoff
  ) {
    return null
  }
  let coverage_end_date = addYears(
    new Date(insurance.insured.patient.person.birth_date),
    ortho_coverage.dependent_age_limit
  )
  switch (ortho_coverage.age_limit_cutoff) {
    case AGE_LIMIT_CUTOFF.END_OF_MONTH:
      return endOfMonth(coverage_end_date)
    case AGE_LIMIT_CUTOFF.END_OF_YEAR:
      return endOfYear(coverage_end_date)
    case AGE_LIMIT_CUTOFF.IMMEDIATE:
    default:
      return coverage_end_date
  }
}

/**
 *
 * @param {keyof typeof INSURANCE_INSTALLMENT_INTERVAL} interval
 * @returns
 */
function getNumberOfMonthsInInterval(interval) {
  switch (interval) {
    case INSURANCE_INSTALLMENT_INTERVAL.QUARTERLY:
      return 3
    case INSURANCE_INSTALLMENT_INTERVAL.YEARLY:
      return 12
    case INSURANCE_INSTALLMENT_INTERVAL.MONTHLY:
    default:
      return 1
  }
}

/**
 *
 * @param {import('./payment-plan.types.ts').Insurance['insured']} a
 * @param {import('./payment-plan.types.ts').Insurance['insured']} b
 * @returns
 */
export function getSortedInsurances(a, b) {
  let a_birth_date = a.insurance_subscription?.person?.birth_date
  let b_birth_date = b.insurance_subscription?.person?.birth_date

  if (!a_birth_date && !b_birth_date) return 0
  if (!a_birth_date) return 1
  if (!b_birth_date) return -1

  // set the same year - comparing the day an month only
  return compareAsc(
    setYear(parseISO(a_birth_date), 2016),
    setYear(parseISO(b_birth_date), 2016)
  )
}

/**
 * IMPORTANT: This function changes the "next" param and is expected to be used in a useDataChange context.
 *
 * @param {*} next param of the change function on payment_plan data context (mutable)
 */
export function changeDiscountsAppliedToTreatmentFee(next) {
  // update the amount of all the discounts applied to treatment's fee
  next.discounts
    .filter(discount => !discount.is_applied_to_payor)
    .forEach(discount => {
      if (discount.type === 'amount') {
        discount.percentage =
          next.treatment_fee > 0
            ? Math.round((discount.amount / next.treatment_fee) * 100)
            : 0
      } else {
        discount.amount = parseFloat(
          ((next.treatment_fee * discount.percentage) / 100).toFixed(2)
        )
      }
    })
}

/**
 * IMPORTANT: This function changes the "next" param and is expected to be used in a useDataChange context.
 *
 * Updates all insurances' estimated reimbursement amount when the treatment fee, discounts or charges changed.
 *
 * @param {*} next param of the change function on payment_plan data context (mutable)
 * @param {import('./payment-plan.types.ts').TreatmentPlan} treatment_plan
 * @returns
 */
export function changeInsurancesEstimatedReimbursementAmount(
  next,
  treatment_plan
) {
  // recalculate insurances' estimated reimbursement amount
  // make sure it's first recalculates the value for primary insuranse since secondary insurance depends on it
  let primary_insurance = next.insurances.find(item => item.is_primary)
  if (
    primary_insurance &&
    primary_insurance.insured_id &&
    // estimated reimbursement overridden by the user, keeping the selection
    !primary_insurance.is_overridden
  ) {
    let {
      estimated_reimbursement_amount:
        primary_insurance_estimated_reimbursement_amount,
      downpayment_amount: primary_insurance_downpayment_amount,
      installment_amount: primary_insurance_installment_amount,
    } = getPrimaryInsuranceEstimatedReimbursement(
      primary_insurance,
      next,
      treatment_plan
    )
    primary_insurance.estimated_reimbursement_amount =
      primary_insurance_estimated_reimbursement_amount
    primary_insurance.downpayment_amount = primary_insurance_downpayment_amount
    primary_insurance.installment_amount = primary_insurance_installment_amount
  }

  let secondary_insurance = next.insurances.find(item => !item.is_primary)
  if (
    secondary_insurance &&
    secondary_insurance.insured_id &&
    !secondary_insurance.is_overridden
  ) {
    let {
      estimated_reimbursement_amount:
        secondary_insurance_estimated_reimbursement_amount,
      downpayment_amount: secondary_insurance_downpayment_amount,
      installment_amount: secondary_insurance_installment_amount,
    } = getSecondaryInsuranceEstimatedReimbursement(
      primary_insurance,
      secondary_insurance,
      next,
      treatment_plan
    )
    secondary_insurance.estimated_reimbursement_amount =
      secondary_insurance_estimated_reimbursement_amount
    secondary_insurance.downpayment_amount =
      secondary_insurance_downpayment_amount
    secondary_insurance.installment_amount =
      secondary_insurance_installment_amount
  }
}

/**
 * IMPORTANT: This function changes the "next" param and is expected to be used in a useDataChange context.
 *
 * Updates all payors' share to reflect the percentage of the remaining fee after applying the discounts, charges and insurance to be paid by each payor.
 *
 * @param {*} next param of the change function on payment_plan data context (mutable)
 */
export function changePayorsShare(next) {
  // update payor's share to take into account the current discount amount
  let treatment_fee = getAdjustedTreatmentFeeAfterInsurances(next)
  next.payors.forEach(payor => {
    payor.share.amount = parseFloat(
      ((treatment_fee * payor.share.percentage) / 100).toFixed(2)
    )
  })
}

/**
 * IMPORTANT: This function changes the "next" param and is expected to be used in a useDataChange context.
 *
 * Updates all the discounts applied to payor's share when the base for calculation changed.
 *
 * @param {*} next param of the change function on payment_plan data context (mutable)
 */
export function changeDiscountsAppliedToPayorsShare(next) {
  // all the payor's discounts should be recalculated to use the updated adjusted treatment fee
  // update discounts' amount to be applied on the new payor's share
  next.discounts
    .filter(discount => discount.is_applied_to_payor)
    .forEach(discount => {
      let payor = getPayorForDiscount(discount, next)
      if (discount.type === 'amount') {
        discount.percentage =
          payor.share.amount > 0
            ? Math.round((discount.amount / payor.share.amount) * 100)
            : 0
      } else {
        discount.amount = parseFloat(
          ((payor.share.amount * discount.percentage) / 100).toFixed(2)
        )
      }
    })
}

/**
 * IMPORTANT: This function changes the "next" param and is expected to be used in a useDataChange context.
 *
 * Update payors' downpayment and installment amount when payor's share or discounts changed.
 *
 * @param {*} next param of the change function on payment_plan data context (mutable)
 */
export function changePayorsConfiguration(next) {
  next.payors.forEach(payor => {
    let { downpayment_amount, installment_amount, payment_plan_length } =
      getUpdatedPayorConfiguration(payor, next)
    payor.downpayment_amount = downpayment_amount
    payor.installment_amount = installment_amount
    payor.payment_plan_length = payment_plan_length
  })
}
